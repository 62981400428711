import React from "react"
import style from "./problems.module.scss"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

function Problems(props) {
  const data = useStaticQuery(query)

  const problems = data.allStrapiProblem.nodes

  const handleProblems = data => {
    return data
      .sort((a, b) => a.pozycja_na_stronie - b.pozycja_na_stronie)
      .map(problem => {
        return (
          <Link
            to={`/problem/${encodeURIComponent(problem.id).toLowerCase()}`}
            style={{ textDecoration: "none" }}
            key={problem.id}
          >
            <div className={style.Problem}>
              <div className={style.ImageHidden}>
                <Img
                  fadeIn="false"
                  loading="eager"
                  Tag={`div`}
                  id={`image-problem`}
                  className={style.Image}
                  fluid={problem.obrazek.childImageSharp.fluid}
                ></Img>
              </div>
              <h3 className={style.ProblemName}>{problem.tytul}</h3>
            </div>
          </Link>
        )
      })
  }

  return (
    <section className={style.Container}>
      <h2 className={style.Title}>{props.title}</h2>

      <div className={style.Flex}>{handleProblems(problems)}</div>
    </section>
  )
}

const query = graphql`
  {
    allStrapiProblem {
      nodes {
        id
        tytul
        pozycja_na_stronie
        obrazek {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default Problems
