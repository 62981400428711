import React from 'react'
import style from './photoGreeting.module.scss'

function PhotoGreeting(props) {
    return (
        <section className={style.MainContainer}>
            <img fadeIn={false} loading="eager" src={props.src} alt={'tło'} className={style.BackgroundImage}></img>
            <div className={style.Content}>
                {props.children}
            </div>
        </section>
    )
}

export default PhotoGreeting
