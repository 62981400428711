import React from "react"
import {graphql} from "gatsby"

import Layout from "../components/layout"
import PhotoGreeting from "../components/photoGreeting/photoGreeting"
import Problems from "../components/problems/problems"
import SEO from "../components/seo"
import blogSample from "../images/problemy.jpeg"
function Problem({data}) {

  return (
    <Layout>
      <SEO title="Twoje problemy" description={data.strapiMetaTag.opis_strony} keywords={data.strapiMetaTag.tagi}/>
      <PhotoGreeting src={blogSample}>
        <h1 style={{ fontSize: "4em", fontWeight: "200" }}>Problemy</h1>
        <h3 style={{ fontSize: "1em", fontWeight: "100" }}>
        Pomożemy ci lepiej wyglądać
        </h3>
      </PhotoGreeting>
      <Problems title={"Wybierz z listy swój problem"} />
    </Layout>
  )
}

export default Problem
export const query = graphql`
query tagsQuery {
  strapiMetaTag(nazwa_strony: {eq: "problem"}) {
    opis_strony
    tagi
  }
}
`